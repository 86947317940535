var temp
let intialState = {
    isAddNotice: false,
    suggestions: [],
    userList: [],
    programs: [],
    sections: [],
    roles: [{ value: 'admin', label: 'Admin', value1: "role" }, { value: 'faculty', label: 'Faculty', value1: "role" }, { value: 'program manager', label: 'Program Manager', value1: "role" }, { value: 'sme', label: 'Subject Controller', value1: "role" }],
    subjects: [],
    isListLoading: true,
    loading: true,
    isloading: true,
    files: null
}

export default function noticeReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Notification_List':
            stateCopy.notification = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy
        case 'Get_Next_Notification_List':
            stateCopy.notification = stateCopy.notification.concat(action.payload);
            return stateCopy;
        case 'Get_Search_Result':
            stateCopy.notification = action.payload;
            return stateCopy
        case 'Get_Search_Result1':
            stateCopy.noticeLists = action.payload;
            return stateCopy
        case 'Get_Notice_List':
            stateCopy.noticeLists = action.payload;
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Get_Next_Notice_List':
            stateCopy.noticeLists = stateCopy.noticeLists.concat(action.payload);
            return stateCopy;
        case 'Get_All_Program':
            stateCopy.programs = action.payload.map(el => [{ value: el._id, label: el.program }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_All_Subjects':
            stateCopy.subjects = action.payload.map(el => [{ value: el._id, label: el.title, }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_Student_Lists':
            stateCopy.userList = action.payload.map(item => ({ ...item, isChecked: false, type: 'student' }));
            stateCopy.userListcopy = stateCopy.userList;
            return stateCopy;
        case 'Get_Staff_Lists':
            stateCopy.staffList = action.payload.map(item => ({ ...item, isChecked: false, type: 'staff' }));
            stateCopy.staffListCopy = stateCopy.staffList;
            return stateCopy;
        case "Select_All":
            if (action.payload.type === 'student') {
                if (action.payload.checked) {
                    stateCopy.userList = stateCopy.userList.map(item => ({ ...item, isChecked: true }));
                }
                else {
                    stateCopy.userList = stateCopy.userList.map(item => ({ ...item, isChecked: false }));
                }
            }
            else {
                if (action.payload.checked) {
                    stateCopy.staffList = stateCopy.staffList.map(item => ({ ...item, isChecked: true }));
                }
                else {
                    stateCopy.staffList = stateCopy.staffList.map(item => ({ ...item, isChecked: false }));
                }
            }
            return stateCopy
        case 'Select_One':
            if (action.payload.type === 'student') {
                if (action.payload.checked) {
                    stateCopy.userList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = true
                        }
                    })
                }
                else {
                    stateCopy.userList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = false
                        }
                    })
                }
            }
            else {
                if (action.payload.checked) {
                    stateCopy.staffList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = true
                        }
                    })
                }
                else {
                    stateCopy.staffList.forEach(el => {
                        if (el._id === action.payload.id) {
                            el.isChecked = false
                        }
                    })
                }
            }

            return stateCopy;
        case 'Select_Selected':
            if (action.payload.type === 'student') {
                if (action.payload.key === 'selected') {
                    stateCopy.userListcopy = stateCopy.userList
                    stateCopy.userList = stateCopy.userList.filter(el => el.isChecked === true)
                }
                else {
                    stateCopy.userList = stateCopy.userListcopy
                }
            }
            else {
                if (action.payload.key === 'selected') {
                    stateCopy.staffListCopy = stateCopy.staffList
                    stateCopy.staffList = stateCopy.staffList.filter(el => el.isChecked === true)
                }
                else {
                    stateCopy.staffList = stateCopy.staffListCopy
                }
            }

            return stateCopy;
        case 'Reset_Userlist':
            stateCopy.userList = [];
            stateCopy.staffList = []
            return stateCopy;
        case 'Get_User_Suggestion':
            // stateCopy.suggestions = action.payload.map(el => [{ value: el._id, label: el.email }][0]);
            stateCopy.suggestions = action.payload;
            return stateCopy;
        case 'Open_Add_Notice':
            stateCopy.isAddNotice = true;
            return stateCopy;
        case 'Close_Add_Notice':
            stateCopy.isAddNotice = false;
            stateCopy.suggestions = [];
            return stateCopy;
        case 'Get_Files':
            stateCopy.files = { title: action.payload.title, url: action.payload.url }
            return stateCopy
        case 'Get_Topics1':
            stateCopy.topics = action.payload.map(el => [{ value: el._id, label: el.title }][0]);
            return stateCopy;
        case 'Get_Subtopics1':
            // stateCopy.subtopics = action.payload;
            var topics = [...new Set(action.payload.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => el.topic))].map(topic => ({ topic, complete: false }));
            const content = topics.map(data => { return action.payload.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic === el.topic }) });
            stateCopy.subtopics = getComplete(topics, content);
            stateCopy.resourcesCopy = action.payload;
            stateCopy.resources = content;
            return stateCopy;
        case 'Save_Notification':
            stateCopy.isAddNotice = false;
            stateCopy.noticeLists = [action.payload, ...stateCopy.noticeLists]
            return stateCopy;
        case 'Reset_User':
            stateCopy.suggestions = [];
            return stateCopy
        case 'Deactivate_Notice':
            stateCopy.noticeLists.forEach(element => {
                if (element._id === action.payload) {
                    element.expired = true;
                }
            });
            return stateCopy;
        case 'Reset_Popups':
            if (action.payload === 'student') {
                stateCopy.userList = stateCopy.userListcopy.map(item => ({ ...item, isChecked: false }));
            }
            else {
                stateCopy.staffList = stateCopy.staffListCopy.map(item => ({ ...item, isChecked: false }));
            }
            return stateCopy;
        case 'Section_List':
            stateCopy.sectionsList = action.payload.map(el => [{ value: el._id, label: el.title }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_Read_Details':
            stateCopy.toArr = action.payload.to.map(item => ({ ...item, isViewed: false }));
            stateCopy.readsArr = action.payload.read;
            stateCopy.toArr.map((a, i) => {
                if (stateCopy.readsArr.some(s => s._id === a._id)) {
                    return a.isViewed = true
                }
                return false
            }
            );
            stateCopy.loading = false;
            return stateCopy;
        case 'Empty_Viewed':
            stateCopy.toArr = [];
            stateCopy.xtraDetail = {};
            stateCopy.loading = true;
            return stateCopy;
        case 'Clear_Details':
            stateCopy.xtraDetail = {};
            stateCopy.isloading = true;
            return stateCopy;
        case 'Get_Extra_Details':
            stateCopy.xtraDetail = action.payload;
            stateCopy.isloading = false;
            return stateCopy;
        case 'Get_Attachment_Resource':
            stateCopy.attachmentResource = action.payload;
            return stateCopy;
        default:
            return stateCopy;

    }
}

const getComplete = (topics, content) => {
    for (var i = 0; i < topics.length; i++) {
        var contentIndex = content[i];
        for (var j = 0; j < contentIndex.length; j++) {
            temp = true;
            if (topics[i].topic === contentIndex[j].topic) {
                if (!contentIndex[j].viewed) {
                    temp = false;
                    break;
                }
            }

        }
        topics[i].complete = temp
    }
    return topics
}